<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
    <AppDialogInfo></AppDialogInfo>
    <AppLoading></AppLoading>
    <AppSnackBar
      v-model="snackBar.show"
      :color="snackBar.color"
      :timeout="snackBar.timeout"
      :message="snackBar.message"
    ></AppSnackBar>
  </v-app>
</template>

<script>
import layout from '@/components/layouts/Layout'
import layoutBlank from '@/components/layouts/LayoutBlank'
import layoutError from '@/components/layouts/LayoutError'
import AppSnackBar from '@/components/base/AppSnackBar.vue'
import AppDialogInfo from '@/components/base/AppDialogInfo.vue'
import AppLoading from '@/components/base/AppLoading.vue'
import { i18nService } from '@/services'
import { i18nHelper } from '@/utils'
import { SHARED } from '@/constants'

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: i18nHelper.getMessage('title.projectName'),
    // all titles will be injected into this template
    titleTemplate: '%s'
  },
  components: {
    layout,
    layoutBlank,
    layoutError,
    AppDialogInfo,
    AppSnackBar,
    AppLoading
  },
  computed: {
    layout() {
      return this.$route.meta.layout || SHARED.LAYOUT_BLANK
    }
  },
  data: () => ({
    snackBar: {
      show: false,
      color: '',
      message: '',
      timeout: null
    }
  }),
  created() {
    this.initApp()
  },
  methods: {
    openSnackBar(color, message, timeout) {
      this.resetSnackBar()
      this.snackBar = {
        show: true,
        color: color,
        message: message,
        timeout: timeout
      }
    },
    resetSnackBar() {
      this.snackBar = {
        show: false,
        color: '',
        message: '',
        timeout: null
      }
    },
    initApp() {
      i18nService.initI18n()
    }
  }
}
</script>
