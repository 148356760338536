import { USER } from '@/api'
import { apiHelper } from '@/utils'
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/store/shared.module'

/* Define Module Name */
const MODULE_NAME = 'user/'

/* Define Action Name */
const A_GET_USERS = 'getUsers'
const A_CREATE_USER = 'createUser'
const A_GET_USER = 'getUser'
const A_UPDATE_USER = 'updateUser'
const A_DELETE_USER = 'deleteUser'
/* Define Reset State Action Name */
const A_INITIAL_GET_USERS_STATE = 'initialGetUsersState'
const A_INITIAL_CREATE_USER_STATE = 'initialCreateUserState'
const A_INITIAL_GET_USER_STATE = 'initialGetUserState'
const A_INITIAL_UPDATE_USER_STATE = 'initialUpdateUserState'
const A_INITIAL_DELETE_USER_STATE = 'initialDeleteUserState'

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_USERS = 'setGetUsers'
const M_CREATE_USER = 'setCreateUser'
const M_GET_USER = 'setGetUser'
const M_UPDATE_USER = 'setUpdateUser'
const M_DELETE_USER = 'setDeleteUser'
/* Define Reset State Mutation Name */
const M_INITIAL_GET_USERS_STATE = 'setInitialUsersState'
const M_INITIAL_CREATE_USER_STATE = 'setInitialCreateUserState'
const M_INITIAL_GET_USER_STATE = 'setInitialGetUserState'
const M_INITIAL_UPDATE_USER_STATE = 'setInitialUpdateUserState'
const M_INITIAL_DELETE_USER_STATE = 'setInitialDeleteUserState'

/* Define Export Name */
export const USER_GET_USERS = MODULE_NAME + A_GET_USERS
export const USER_CREATE_USER = MODULE_NAME + A_CREATE_USER
export const USER_GET_USER = MODULE_NAME + A_GET_USER
export const USER_UPDATE_USER = MODULE_NAME + A_UPDATE_USER
export const USER_DELETE_USER = MODULE_NAME + A_DELETE_USER
/* Define Reset State Name */
export const USER_INITIAL_GET_USERS_STATE =
  MODULE_NAME + A_INITIAL_GET_USERS_STATE
export const USER_INITIAL_CREATE_USER_STATE =
  MODULE_NAME + A_INITIAL_CREATE_USER_STATE
export const USER_INITIAL_GET_USER_STATE =
  MODULE_NAME + A_INITIAL_GET_USER_STATE
export const USER_INITIAL_UPDATE_USER_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_USER_STATE
export const USER_INITIAL_DELETE_USER_STATE =
  MODULE_NAME + A_INITIAL_DELETE_USER_STATE

const state = {
  users: {
    code: 0,
    complete: false,
    message: null,
    data: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  newUser: {
    code: 0,
    complete: false,
    message: null,
    data: null
  },
  user: {
    code: 0,
    complete: false,
    message: null,
    data: null
  },
  updateUser: {
    code: 0,
    complete: false,
    message: null,
    data: null
  },
  deleteUser: {
    code: 0,
    complete: false,
    message: null,
    data: null
  }
}

//Getters
const getters = {}

// Actions
const actions = {
  async [A_GET_USERS]({ dispatch, commit }, { data }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    await USER.getUsers(data)
      .then((response) => {
        result = response
        commit(M_GET_USERS, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
      .catch((error) => {
        result = error
        commit(M_GET_USERS, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
  },
  [A_CREATE_USER]({ dispatch, commit }, { data }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    USER.createUser(data)
      .then((response) => {
        result = response
        commit(M_CREATE_USER, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
      .catch((error) => {
        result = error
        commit(M_CREATE_USER, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
  },
  [A_GET_USER]({ dispatch, commit }, { id }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    USER.getUser(id)
      .then((response) => {
        result = response
        commit(M_GET_USER, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
      .catch((error) => {
        result = error
        commit(M_GET_USER, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
  },
  [A_UPDATE_USER]({ dispatch, commit }, { id, data }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    USER.updateUser(id, data)
      .then((response) => {
        result = response
        commit(M_UPDATE_USER, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
      .catch((error) => {
        result = error
        commit(M_UPDATE_USER, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
  },
  [A_UPDATE_USER]({ dispatch, commit }, { id, data }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    USER.updateUser(id, data)
      .then((response) => {
        result = response
        commit(M_UPDATE_USER, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
      .catch((error) => {
        result = error
        commit(M_UPDATE_USER, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
  },
  [A_DELETE_USER]({ dispatch, commit }, { id }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    USER.deleteUser(id)
      .then((response) => {
        result = response
        commit(M_DELETE_USER, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
      .catch((error) => {
        result = error
        commit(M_DELETE_USER, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
  },
  [A_INITIAL_GET_USERS_STATE]({ commit }) {
    commit(M_INITIAL_GET_USERS_STATE)
  },
  [A_INITIAL_CREATE_USER_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_USER_STATE)
  },
  [A_INITIAL_GET_USER_STATE]({ commit }) {
    commit(M_INITIAL_GET_USER_STATE)
  },
  [A_INITIAL_UPDATE_USER_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_USER_STATE)
  },
  [A_INITIAL_DELETE_USER_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_USER_STATE)
  }
}

// Mutations
const mutations = {
  [M_GET_USERS](state, result) {
    if (result.code === 0) {
      let p = result.pagination

      state.users = {
        code: result.code,
        complete: true,
        message: null,
        data: result.data.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            username: x.username,
            name: x.name,
            createdAt: x.created_at,
            updatedAt: x.updated_at
          }
        }),
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      }
    } else {
      state.users = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message),
        data: null,
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      }
    }
  },
  [M_CREATE_USER](state, result) {
    state.newUser = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result.message),
      data: null
    }
  },
  [M_GET_USER](state, result) {
    if (result.code === 0) {
      let d = result.data

      state.user = {
        code: result.code,
        complete: true,
        message: null,
        data: {
          id: d.id,
          username: d.username,
          name: d.name,
          createdAt: d.created_at,
          updatedAt: d.updated_at
        }
      }
    } else {
      state.user = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message),
        data: null
      }
    }
  },
  [M_UPDATE_USER](state, result) {
    state.updateUser = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result.message),
      data: null
    }
  },
  [M_DELETE_USER](state, result) {
    state.deleteUser = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result.message),
      data: null
    }
  },
  [M_INITIAL_GET_USERS_STATE](state) {
    state.users = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    }
  },
  [M_INITIAL_CREATE_USER_STATE](state) {
    state.newUser = {
      code: 0,
      complete: false,
      message: null
    }
  },
  [M_INITIAL_GET_USER_STATE](state) {
    state.user = {
      code: 0,
      complete: false,
      data: null,
      message: null
    }
  },
  [M_INITIAL_UPDATE_USER_STATE](state) {
    state.updateUser = {
      code: 0,
      complete: false,
      data: null,
      message: null
    }
  },
  [M_INITIAL_DELETE_USER_STATE](state) {
    state.deleteUser = {
      code: 0,
      complete: false,
      data: null,
      message: null
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
