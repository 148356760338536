import { ROUTE_NAME, SHARED } from '@/constants'

const routes = [
  {
    path: '/home',
    name: ROUTE_NAME.HOME,
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: SHARED.LAYOUT_DEFAULT,
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: ROUTE_NAME.LOGIN,
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: SHARED.LAYOUT_BLANK,
      requiresAuth: false
    }
  },
  {
    path: '/user',
    name: ROUTE_NAME.USER,
    redirect: { name: ROUTE_NAME.USER_LIST },
    component: () => import('@/views/user/User.vue'),
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.USER_LIST,
        component: () => import('@/views/user/UserList.vue'),
        meta: {
          name: 'user',
          layout: SHARED.LAYOUT_DEFAULT,
          requiresAuth: true
        }
      },
      {
        path: 'new',
        name: ROUTE_NAME.USER_NEW,
        component: () => import('@/views/user/UserNew.vue'),
        meta: {
          name: 'userNew',
          layout: SHARED.LAYOUT_DEFAULT,
          requiresAuth: true
        }
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.USER_DETAILS,
        component: () => import('@/views/user/UserDetails.vue'),
        meta: {
          name: 'userDetails',
          layout: SHARED.LAYOUT_DEFAULT,
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/contact-application',
    name: ROUTE_NAME.CONTACT_APPLICATION,
    redirect: { name: ROUTE_NAME.CONTACT_APPLICATION_LIST },
    component: () =>
      import('@/views/contact-application/ContactApplication.vue'),
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.CONTACT_APPLICATION_LIST,
        component: () =>
          import('@/views/contact-application/ContactApplicationList.vue'),
        meta: {
          name: 'contactApplicationList',
          layout: SHARED.LAYOUT_DEFAULT,
          requiresAuth: true
        }
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.CONTACT_APPLICATION_DETAILS,
        component: () =>
          import('@/views/contact-application/ContactApplicationDetails.vue'),
        meta: {
          name: 'contactApplicationDetails',
          layout: SHARED.LAYOUT_DEFAULT,
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/news-and-events',
    name: ROUTE_NAME.NEWS_AND_EVENTS,
    redirect: { name: ROUTE_NAME.NEWS_AND_EVENTS_LIST },
    component: () => import('@/views/news-and-events/NewsAndEvents.vue'),
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.NEWS_AND_EVENTS_LIST,
        component: () =>
          import('@/views/news-and-events/NewsAndEventsList.vue'),
        meta: {
          name: 'newsAndEventsList',
          layout: SHARED.LAYOUT_DEFAULT,
          requiresAuth: true
        }
      },
      {
        path: 'new',
        name: ROUTE_NAME.NEWS_AND_EVENTS_NEW,
        component: () => import('@/views/news-and-events/NewsAndEventsNew.vue'),
        meta: {
          name: 'newsAndEventsNew',
          layout: SHARED.LAYOUT_DEFAULT,
          requiresAuth: true
        }
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.NEWS_AND_EVENTS_DETAILS,
        component: () =>
          import('@/views/news-and-events/NewsAndEventsDetails.vue'),
        meta: {
          name: 'newsAndEventsDetails',
          layout: SHARED.LAYOUT_DEFAULT,
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/error',
    component: () => import('@/views/error/Error.vue'),
    children: [
      {
        path: '404',
        name: ROUTE_NAME.ERROR_404,
        component: () => import('@/views/error/404.vue'),
        meta: {
          name: 'error',
          layout: SHARED.LAYOUT_ERROR,
          requiresAuth: false
        }
      },
      {
        path: '403',
        name: ROUTE_NAME.ERROR_403,
        component: () => import('@/views/error/403.vue'),
        meta: {
          name: 'error',
          layout: SHARED.LAYOUT_ERROR,
          requiresAuth: false
        }
      }
    ]
  },
  {
    path: '/',
    redirect: { name: ROUTE_NAME.LOGIN }
  },
  {
    path: '*',
    redirect: { name: ROUTE_NAME.ERROR_404 }
  }
]

export default routes
