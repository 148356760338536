import { uiHelper } from '@/utils'
import { JWT, LOCAL } from '@/constants'

export const jwtHelper = {
  getToken,
  setToken,
  removeToken,
  getBearerToken,
  getLastActivityTime,
  setLastActivityTime,
  removeLastActivityTime,
  setSessionExpirationTime,
  getSessionExpirationTime,
  removeSessionExpirationTime
}

function getToken() {
  return uiHelper.getLocalStorage(LOCAL.TOKEN)
}

function setToken(keyValue) {
  uiHelper.setLocalStorage(LOCAL.TOKEN, keyValue)
}

function removeToken() {
  uiHelper.removeLocalStorage(LOCAL.TOKEN)
}

function getBearerToken() {
  return JWT.JWT_TOKEN_TYPE + uiHelper.getLocalStorage(LOCAL.TOKEN)
}

function getLastActivityTime() {
  return parseInt(uiHelper.getLocalStorage(LOCAL.LAST_ACTIVITY_TIME))
}

function setLastActivityTime() {
  uiHelper.setLocalStorage(
    LOCAL.LAST_ACTIVITY_TIME,
    JSON.stringify(new Date().getTime())
  )
}

function removeLastActivityTime() {
  uiHelper.removeLocalStorage(LOCAL.LAST_ACTIVITY_TIME)
}

function setSessionExpirationTime(time) {
  let originalExpirationHour = time * 1000
  let newTime = time != 0 ? new Date().getTime() + originalExpirationHour : 0

  uiHelper.setLocalStorage(
    LOCAL.SESSION_EXPIRATION_TIME,
    JSON.stringify(newTime)
  )
}

function getSessionExpirationTime() {
  return parseInt(uiHelper.getLocalStorage(LOCAL.SESSION_EXPIRATION_TIME))
}

function removeSessionExpirationTime() {
  uiHelper.removeLocalStorage(LOCAL.SESSION_EXPIRATION_TIME)
}
