<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    floating
    class="px-2 elevation-4"
    mobile-breakpoint="md"
  >
    <div class="py-4 px-2">
      <v-img
        height="50"
        :src="`/img/logo/logo-${isDark ? 'white' : 'black'}.png`"
      ></v-img>
    </div>
    <template v-for="(category, categoryIndex) in drawerCategory">
      <div
        :key="categoryIndex"
        v-if="drawerList.find((x) => x.category == category)"
      >
        <label class="d-block pa-2 text-uppercase label--text">{{
          $t(`text.${category}`)
        }}</label>
        <v-list nav dense>
          <template
            v-for="(items, index) in drawerList.filter(
              (x) => x.category == category
            )"
          >
            <div :key="index">
              <v-list-item
                v-if="items.subGroup.length < 1"
                :to="{ name: items.route }"
                active-class="primary surface--text"
              >
                <v-list-item-icon>
                  <v-icon>{{ items.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title class="text-capitalize">{{
                  $t(`aside.${items.name}`)
                }}</v-list-item-title>
              </v-list-item>

              <v-list-group v-else v-model="items.active" no-action>
                <template v-slot:activator>
                  <v-list-item-icon>
                    <v-icon>{{ items.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="text-capitalize">{{
                    $t(`aside.${items.name}`)
                  }}</v-list-item-title>
                </template>
                <v-list-item
                  v-for="(subItems, i) in items.subGroup"
                  :key="i"
                  :to="{ name: subItems.route }"
                  active-class="primary surface--text"
                  class="px-2"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-minus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title
                    class="text-capitalize"
                    v-text="$t(`aside.${subItems.name}`)"
                  >
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>
            </div>
          </template>
        </v-list>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { ACCESS_RIGHTS_SERVICE } from '@/services'
import { ASIDE_CATEGORY } from '@/constants'

export default {
  name: 'Aside',
  props: {
    show: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    drawer: true,
    accessRightsService: ACCESS_RIGHTS_SERVICE,
    drawerCategory: [
      ASIDE_CATEGORY.MAIN,
      ASIDE_CATEGORY.APP,
      ASIDE_CATEGORY.REPORT,
      ASIDE_CATEGORY.ADMIN,
      ASIDE_CATEGORY.CONFIGURATION
    ]
  }),
  computed: {
    isDark() {
      return this.$vuetify.theme.isDark
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    drawerList() {
      let list = ACCESS_RIGHTS_SERVICE.getAsideList()
      let parentName = this.$route.meta.parent

      list.forEach((x) => {
        let isActive = false
        x.subGroup.forEach((y) => {
          if (y.route == parentName) {
            isActive = true
          }
        })

        x.active = isActive
      })

      return list
    }
  },
  watch: {
    isMobile() {
      if (this.isMobile) {
        if (this.show && this.drawer) {
          this.drawer = false
        }
      } else {
        if (!this.show && !this.drawer) {
          this.updateParent(true)
        }
      }
    },
    show() {
      this.drawer = this.show
    },
    drawer() {
      if (this.show && !this.drawer) {
        this.updateParent(false)
      }
    }
  },
  created() {
    this.initAside()
  },
  methods: {
    updateParent(value) {
      this.$emit('update', value)
    },
    initAside() {
      if (this.isMobile) {
        this.drawer = false
        this.updateParent(false)
      }
    }
  }
}
</script>

<style></style>
