import { apiService } from '@/services'

export const NEWS_AND_EVENTS = {
  getNewsAndEventsList,
  createNewsAndEvents,
  getNewsAndEvents,
  updateNewsAndEvents,
  deleteNewsAndEvents,
  uploadNewsAndEventsImage
}

const URL = {
  ADMIN: 'admin',
  NEWS_AND_EVENTS: 'news-and-events',
  UPLOAD_IMAGE: 'upload-image'
}

function getNewsAndEventsList(data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      page: data.page
    }
  }
  return apiService().get(
    `/${URL.ADMIN}/${URL.NEWS_AND_EVENTS}`,
    requestOptions
  )
}

function createNewsAndEvents(data) {
  let requestBody = {
    title: data.title,
    description: data.description,
    link: data.link
  }
  return apiService().post(`/${URL.ADMIN}/${URL.NEWS_AND_EVENTS}`, requestBody)
}

function getNewsAndEvents(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.NEWS_AND_EVENTS}/${id}`)
}

function updateNewsAndEvents(id, data) {
  let requestBody = {
    title: data.title,
    description: data.description,
    link: data.link
  }
  return apiService().put(
    `/${URL.ADMIN}/${URL.NEWS_AND_EVENTS}/${id}`,
    requestBody
  )
}

function deleteNewsAndEvents(id) {
  return apiService().delete(`/${URL.ADMIN}/${URL.NEWS_AND_EVENTS}/${id}`)
}

function uploadNewsAndEventsImage(id, formData) {
  return apiService(null, true, true).post(
    `/${URL.ADMIN}/${URL.NEWS_AND_EVENTS}/${id}/${URL.UPLOAD_IMAGE}`,
    formData
  )
}
