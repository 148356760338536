<template>
  <v-row no-gutters class="wh-100">
    <v-col cols="12" md="5" class="d-flex justify-md-end align-center pt-12">
      <slot />
    </v-col>
    <v-col
      v-if="this.$vuetify.breakpoint.mdAndUp"
      cols="7"
      class="d-flex justify-center align-center"
    >
      <!-- Place your vector -->
      <v-img :width="imageWidth" :src="vectorSrc"></v-img>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { ROUTE_NAME } from '@/constants'
import { AUTH_IS_AUTHENTICATED } from '@/store/auth.module'

export default {
  name: 'LayoutError',
  computed: {
    ...mapGetters({
      isAuthenticated: [AUTH_IS_AUTHENTICATED]
    }),
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 220
        case 'sm':
          return 500
        case 'md':
          return 500
        case 'lg':
          return 600
        case 'xl':
          return 600
        default:
          return 600
      }
    },
    vectorSrc() {
      switch (this.$route.name) {
        case ROUTE_NAME.ERROR_403:
          return '/img/vector/forbidden.png'
        default:
          return '/img/vector/error.png'
      }
    }
  },
  methods: {
    backToInitialPage() {
      if (this.isAuthenticated) {
        this.$router.push({ name: ROUTE_NAME.HOME })
      } else {
        this.$router.push({ name: ROUTE_NAME.LOGIN })
      }
    }
  }
}
</script>

<style></style>
