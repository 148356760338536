<template>
  <v-dialog
    persistent
    v-model="show"
    :scrollable="scrollable"
    :max-height="maxHeight"
    :max-width="maxWidth"
    content-class="elevation-0 app-dialog-blank"
  >
    <div class="d-flex justify-center align-center pa-2">
      <v-card
        class="app-dialog-blank-card pt-6 pb-4 px-6"
        :max-width="contentMaxWidth"
        :min-width="contentMinWidth"
      >
        <v-card-title class="text-h6 font-weight-regular pa-0 py-2">
          {{ title }}
        </v-card-title>

        <v-container
          v-resize="onResize"
          class="content-container px-0"
          :style="contentContainerMaxHeight"
        >
          <slot name="content" class="py-4" />
        </v-container>

        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end py-0 pt-2">
          <template v-for="(items, index) in buttons">
            <v-btn
              :key="index"
              :color="items.color"
              :outlined="items.outlined"
              depressed
              class="text-caption"
            >
              {{ items.text }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppDialogBlank',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    maxHeight: {
      type: Number,
      required: false
    },
    maxWidth: {
      type: Number,
      required: false
    },
    contentMaxWidth: {
      type: Number,
      required: false
    },
    contentMinWidth: {
      type: Number,
      required: false,
      default: 300
    },
    scrollable: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: 'Blank Dialog Title'
    },
    buttons: {
      type: Array,
      required: false,
      default: () => {
        return [
          {
            text: 'Close',
            color: 'primary',
            action: () => {}
          }
        ]
      }
    }
  },
  data: () => ({
    widowHeight: 0
  }),
  computed: {
    contentContainerMaxHeight() {
      return `max-height: ${this.widowHeight}px;`
    }
  },
  methods: {
    onResize() {
      this.widowHeight = window.innerHeight - 200
    }
  },
  mounted() {
    this.onResize
  }
}
</script>
<style lang="scss">
.app-dialog-blank {
  max-height: 100% !important;
}
.app-dialog-blank-card {
  border-radius: 16px !important;
  .content-container {
    overflow: auto;
  }
}
</style>
