<template>
  <div class="full-width">
    <!-- Text Field Start -->
    <v-text-field
      v-if="type == formFieldType.TEXT || type == formFieldType.PASSWORD"
      :append-icon="
        type == 'password' && allowShowPassword
          ? showPassword
            ? 'mdi-eye'
            : 'mdi-eye-off'
          : appendIcon
      "
      :append-outer-icon="appendOuterIcon"
      :prepend-icon="prependIcon"
      :prepend-inner-icon="prependInnerIcon"
      :background-color="backgroundColor"
      :clearable="clearable"
      :counter="counter"
      :disabled="disabled"
      :full-width="fullWidth"
      :height="height"
      :hide-details="hideDetails"
      :hint="hint"
      :label="label"
      :placeholder="placeholder"
      :readonly="readonly"
      :type="
        type == formFieldType.PASSWORD
          ? showPassword
            ? 'text'
            : 'password'
          : type
      "
      :rules="
        isOptional
          ? value != null && value != undefined && value != ''
            ? rules
            : []
          : rules
      "
      :dense="dense"
      :flat="flat"
      :outlined="outlined"
      :rounded="rounded"
      :color="color"
      :shaped="shaped"
      :solo="solo"
      :suffix="suffix"
      :prefix="prefix"
      :value="value"
      @input="input"
      @blur="blur"
      @focus="focus"
      @keydown="keydown"
      @change="change"
      @click:append="
        type == formFieldType.PASSWORD
          ? (showPassword = !showPassword)
          : (showPassword = !showPassword)
      "
    >
      <template v-slot:append-outer>
        <slot name="text-field-append-outer" />
      </template>
      <template v-slot:append-icon>
        <slot name="text-field-append-icon" />
      </template>
      <template v-slot:prepend-icon>
        <slot name="text-field-prepend-icon" />
      </template>
      <template v-slot:prepend-inner-icon>
        <slot name="text-field-prepend-inner-icon" />
      </template>
    </v-text-field>
    <!-- Text Field End -->

    <!-- Select Field Start -->
    <v-select
      v-if="type == formFieldType.SELECT"
      :append-icon="appendIcon"
      :append-outer-icon="appendOuterIcon"
      :prepend-icon="prependIcon"
      :prepend-inner-icon="prependInnerIcon"
      :clearable="clearable"
      :multiple="multiple"
      :items="items"
      :value="value"
      :disabled="disabled"
      :readonly="readonly"
      :label="label"
      :chips="chips"
      :placeholder="placeholder"
      :hide-details="hideDetails"
      :hint="hint"
      :rules="
        isOptional
          ? value != null && value != undefined && value != ''
            ? rules
            : []
          : rules
      "
      :item-text="itemText"
      :item-value="itemValue"
      :dense="dense"
      :flat="flat"
      :outlined="outlined"
      :rounded="rounded"
      :color="color"
      :shaped="shaped"
      :solo="solo"
      @input="input"
      @change="change"
    >
      <template v-slot:append-outer>
        <slot name="text-field-append-outer" />
      </template>
      <template v-slot:append-icon>
        <slot name="text-field-append-icon" />
      </template>
      <template v-slot:prepend-icon>
        <slot name="text-field-prepend-icon" />
      </template>
      <template v-slot:prepend-inner-icon>
        <slot name="text-field-prepend-inner-icon" />
      </template>
    </v-select>
    <!-- Select Field End -->

    <!-- Text Area Field Start -->
    <v-textarea
      v-if="type == formFieldType.TEXTAREA"
      :auto-grow="autoGrow"
      :rows="rows"
      :append-icon="appendIcon"
      :append-outer-icon="appendOuterIcon"
      :prepend-icon="prependIcon"
      :prepend-inner-icon="prependInnerIcon"
      :background-color="backgroundColor"
      :clearable="clearable"
      :counter="counter"
      :disabled="disabled"
      :full-width="fullWidth"
      :height="height"
      :hide-details="hideDetails"
      :hint="hint"
      :label="label"
      :placeholder="placeholder"
      :readonly="readonly"
      :type="type"
      :rules="
        isOptional
          ? value != null && value != undefined && value != ''
            ? rules
            : []
          : rules
      "
      :dense="dense"
      :flat="flat"
      :outlined="outlined"
      :rounded="rounded"
      :color="color"
      :shaped="shaped"
      :solo="solo"
      :suffix="suffix"
      :prefix="prefix"
      :value="value"
      @input="input"
      @blur="blur"
      @focus="focus"
      @keydown="keydown"
      @change="change"
    >
      <template v-slot:append-outer>
        <slot name="textarea-append-outer" />
      </template>
      <template v-slot:append-icon>
        <slot name="textarea-append-icon" />
      </template>
      <template v-slot:prepend-icon>
        <slot name="textarea-prepend-icon" />
      </template>
      <template v-slot:prepend-inner-icon>
        <slot name="textarea-prepend-inner-icon" />
      </template>
    </v-textarea>
    <!-- Select Field End -->

    <!-- Date Field Start -->
    <v-menu
      v-if="type == formFieldType.DATE"
      v-model="menuDatePicker"
      :close-on-content-click="false"
      :disabled="disabled || readonly"
      max-width="290"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-bind="attrs"
          v-on="on"
          :full-width="fullWidth"
          :height="height"
          :hide-details="hideDetails"
          :hint="hint"
          :label="label"
          :placeholder="placeholder"
          :rules="
            isOptional
              ? date != null && date != undefined && date != ''
                ? rules
                : []
              : rules
          "
          :dense="dense"
          :flat="flat"
          :outlined="outlined"
          :rounded="rounded"
          :color="color"
          :shaped="shaped"
          :solo="solo"
          :suffix="suffix"
          :value="range ? dateRangeText : date"
          :clearable="!disabled && !readonly"
          show-current
          readonly
          @click:clear="clearDate"
        >
        </v-text-field>
      </template>
      <v-card v-show="menuDatePicker">
        <v-date-picker
          v-model="date"
          :max="maxDate"
          :range="range"
          color="primary"
        ></v-date-picker>
        <v-divider></v-divider>
        <div class="text-end pa-2">
          <v-btn
            block
            :disabled="date == ''"
            class="primary"
            @click="chooseDate"
            >{{ $t('text.ok') }}</v-btn
          >
        </div>
      </v-card>
    </v-menu>
    <!-- Date Field End -->

    <!-- Date Time Field Start -->
    <v-menu
      v-if="type == formFieldType.DATE_TIME"
      v-model="menuDatePicker"
      :close-on-content-click="false"
      :disabled="disabled || readonly"
      max-width="290"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-bind="attrs"
          v-on="on"
          :full-width="fullWidth"
          :height="height"
          :hide-details="hideDetails"
          :hint="hint"
          :label="label"
          :placeholder="placeholder"
          :rules="
            isOptional
              ? dateTime != null && dateTime != undefined && dateTime != ''
                ? rules
                : []
              : rules
          "
          :dense="dense"
          :flat="flat"
          :outlined="outlined"
          :rounded="rounded"
          :color="color"
          :shaped="shaped"
          :solo="solo"
          :suffix="suffix"
          :value="dateTime"
          :clearable="!disabled && !readonly"
          show-current
          readonly
          @click:clear="clearDateTime"
        >
        </v-text-field>
      </template>
      <v-card v-show="menuDatePicker" tile>
        <v-tabs grow>
          <v-tab v-for="item in dateTimeItems" :key="item">
            {{ $t(`text.${item}`) }}
          </v-tab>

          <v-tab-item>
            <v-date-picker
              v-model="date"
              :max="maxDate"
              :range="range"
              color="primary"
              class="rounded-0"
            ></v-date-picker>
          </v-tab-item>
          <v-tab-item>
            <v-time-picker
              v-model="time"
              ampm-in-title
              format="ampm"
              use-seconds
              color="primary"
              class="rounded-0"
            ></v-time-picker>
          </v-tab-item>
        </v-tabs>

        <v-divider></v-divider>
        <div class="text-end pa-2">
          <v-btn
            block
            :disabled="!date || !time"
            class="primary"
            @click="chooseDateTime"
            >{{ $t('text.ok') }}</v-btn
          >
        </div>
      </v-card>
    </v-menu>
    <!-- Date Time Field End -->

    <!-- Image Field Start -->
    <div v-if="type == formFieldType.IMAGE_FILE_INPUT">
      <div v-if="value || readonly">
        <v-img :src="value">
          <div class="wh-100 text-end">
            <v-btn
              v-if="!disabled && !readonly"
              fab
              large
              color="error"
              class="ma-2"
              @click="input('')"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </div>
        </v-img>
      </div>
      <div v-else class="d-flex">
        <v-file-input
          v-model="files"
          accept="image/*"
          truncate-length="15"
          :append-outer-icon="appendOuterIcon ? appendOuterIcon : ''"
          :prepend-icon="prependIcon ? prependIcon : ''"
          :prepend-inner-icon="
            prependInnerIcon ? prependInnerIcon : 'mdi-paperclip'
          "
          :background-color="backgroundColor"
          :disabled="disabled"
          :full-width="fullWidth"
          :height="height"
          :hide-details="hideDetails"
          :hint="hint"
          :label="label"
          :placeholder="placeholder"
          :rules="
            isOptional
              ? value != null && value != undefined && value != ''
                ? rules
                : []
              : rules
          "
          :dense="dense"
          :flat="flat"
          :outlined="outlined"
          :rounded="rounded"
          :color="color"
          :shaped="shaped"
          :solo="solo"
          :suffix="suffix"
          :prefix="prefix"
          :show-size="1000"
          class="pr-4"
        ></v-file-input>
        <v-btn
          :disabled="!files"
          height="40"
          color="blue-grey"
          class="white--text"
          @click="uploadFile"
        >
          {{ $t('text.upload') }}
          <v-icon right dark> mdi-cloud-upload </v-icon>
        </v-btn>
      </div>
    </div>
    <!-- Image Field End -->

    <!-- Rich Text Field Start -->
    <div v-if="type == formFieldType.RICH_TEXT">
      <VueEditor
        v-if="!readonly"
        :value="value"
        :disabled="disabled"
        :editor-toolbar="customEditorToolbar"
        @input="input"
      ></VueEditor>
      <v-card v-else flat outlined class="pa-6" v-html="value"></v-card>
    </div>
    <!-- Rich Text Field End -->
  </div>
</template>

<script>
import { SHARED, FORM_FIELD_TYPE } from '@/constants'

export default {
  name: 'AppFormField',
  props: {
    type: {
      type: String,
      default: FORM_FIELD_TYPE.TEXT,
      required: false
    },
    presetStyle: {
      type: Boolean,
      default: true,
      required: false
    },
    presetType: {
      type: Number,
      default: 1,
      required: false
    },
    blur: {
      type: Function,
      default: function () {},
      required: false
    },
    focus: {
      type: Function,
      default: function () {},
      required: false
    },
    keydown: {
      type: Function,
      default: function () {},
      required: false
    },
    change: {
      type: Function,
      default: function () {},
      required: false
    },
    isOptional: {
      type: Boolean,
      default: false,
      required: false
    },
    appendIcon: {
      type: String,
      required: false
    },
    appendOuterIcon: {
      type: String,
      required: false
    },
    prependIcon: {
      type: String,
      required: false
    },
    prependInnerIcon: {
      type: String,
      required: false
    },
    backgroundColor: {
      type: String,
      required: false
    },
    clearable: {
      type: Boolean,
      default: false,
      required: false
    },
    color: {
      type: String,
      required: false
    },
    counter: {
      type: Number,
      required: false
    },
    dense: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    flat: {
      type: Boolean,
      default: false,
      required: false
    },
    fullWidth: {
      type: Boolean,
      default: false,
      required: false
    },
    height: {
      type: Number,
      required: false
    },
    hideDetails: {
      type: Boolean,
      default: false,
      required: false
    },
    hint: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    outlined: {
      type: Boolean,
      default: false,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false
    },
    rounded: {
      type: Boolean,
      default: false,
      required: false
    },
    rules: {
      type: Array,
      required: false
    },
    shaped: {
      type: Boolean,
      default: false,
      required: false
    },
    solo: {
      type: Boolean,
      default: false,
      required: false
    },
    suffix: {
      type: String,
      required: false
    },
    prefix: {
      type: String,
      required: false
    },
    value: {
      default: null,
      required: false
    },
    allowShowPassword: {
      type: Boolean,
      default: false,
      required: false
    },
    items: {
      type: Array,
      default: () => [],
      required: false
    },
    itemText: {
      type: String,
      default: 'text',
      required: false
    },
    itemValue: {
      type: String,
      default: 'value',
      required: false
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false
    },
    chips: {
      type: Boolean,
      default: false,
      required: false
    },
    autoGrow: {
      type: Boolean,
      default: true,
      required: false
    },
    rows: {
      type: Number,
      default: 1,
      required: false
    },
    range: {
      type: Boolean,
      default: true,
      required: false
    },
    maxDate: {
      type: String,
      default: SHARED.DEFAULT_DATE_TODAY,
      required: false
    }
  },
  data: () => ({
    showPassword: false,
    formFieldType: FORM_FIELD_TYPE,
    dateTime: '',
    date: '',
    time: '00:00:00',
    menuDatePicker: false,
    dateTimeItems: ['date', 'time'],
    customEditorToolbar: [
      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }], // outDent/indent
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      ['blockquote', 'code-block', { align: [] }, { direction: 'rtl' }, 'link'],
      ['clean'] // remove formatting button
    ],
    files: null
  }),
  computed: {
    dateRangeText() {
      return this.date ? this.date.join(' ~ ') : ''
    }
  },
  watch: {
    value() {
      this.importDisplayValue()
      this.files = null
    }
  },
  created() {
    this.initAppFormField()
  },
  methods: {
    uploadFile() {
      this.$emit('upload-file', this.files)
    },
    input(value) {
      this.$emit('input', value)
    },
    chooseDateTime() {
      this.dateTime = this.date + ' ' + this.time
      this.$emit('input', this.dateTime)
      this.menuDatePicker = false
    },
    chooseDate() {
      if (this.date.length < 2) {
        this.date.push(this.maxDate)
      }
      this.$emit('input', this.date)
      this.menuDatePicker = false
    },
    clearDate() {
      this.range ? (this.date = []) : (this.date = '')
      this.$emit('input', this.date)
    },
    clearDateTime() {
      this.$emit('input', this.dateTime)
    },
    importDisplayValue() {
      let type = this.type

      if ([FORM_FIELD_TYPE.DATE, FORM_FIELD_TYPE.DATE_TIME].includes(type)) {
        if (type == FORM_FIELD_TYPE.DATE) {
          this.date = this.value
        } else if (type == FORM_FIELD_TYPE.DATE_TIME) {
          let v = this.value.split(' ')

          this.date = v[0]
          this.time = v[1]
          this.dateTime = this.value
        }
      }
    },
    initAppFormField() {
      if (this.range) {
        this.date = []
      }
      this.importDisplayValue()
    }
  }
}
</script>

<style></style>
