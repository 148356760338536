import Vue from 'vue'
import Vuex from 'vuex'

import shared from './shared.module'
import auth from './auth.module'
import user from './user.module'
import contactApplication from './contact-application.module'
import newsAndEvents from './news-and-events.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    shared,
    auth,
    user,
    contactApplication,
    newsAndEvents
  }
})
