import { i18nHelper } from '@/utils'
import { ACCESS_RIGHTS_SERVICE } from '@/services'
import {
  SHARED_OPEN_DIALOG_INFO,
  SHARED_CLOSE_DIALOG_INFO
} from '@/store/shared.module'

const commonMixin = {
  methods: {
    checkAccessRights(permissions) {
      return ACCESS_RIGHTS_SERVICE.checkUserAccessRight(permissions)
    },
    mapArrayToObjectData(array) {
      let newData = {}

      array.forEach((x) => {
        newData[x.key] = x.model
      })

      return newData
    },
    backToParentPage(routeName) {
      this.$router.push({ name: routeName })
    },
    openRequestAddAppDialogInfo(title, addOnOkAction) {
      let type = 'info'
      let description = [i18nHelper.getMessage('message.requestAddMessage')]
      let buttons = []

      buttons.push({
        text: i18nHelper.getMessage('text.cancel'),
        outlined: true,
        action: () => {
          this.closeAppDialogInfo()
        }
      })

      buttons.push({
        text: i18nHelper.getMessage('text.confirm'),
        color: 'primary',
        action: () => {
          addOnOkAction()
          this.closeAppDialogInfo()
        }
      })

      this.openAppDialogInfo(type, title, description, buttons)
    },
    openSuccessAppDialogInfo(title, message, addOnOkAction) {
      let type = 'success'
      let description = []
      let buttons = []
      let emptyAction = () => {}

      description.push(message)

      buttons.push({
        text: i18nHelper.getMessage('text.ok'),
        color: 'primary',
        action: () => {
          addOnOkAction ? addOnOkAction() : emptyAction()
          this.closeAppDialogInfo()
        }
      })

      this.openAppDialogInfo(type, title, description, buttons)
    },
    openErrorAppDialogInfo(title, response, addOnOkAction) {
      let type = 'error'
      let description = []
      let buttons = []
      let emptyAction = () => {}

      description.push(
        i18nHelper.getMessage('responseFormat.code', [response.code]),
        i18nHelper.getMessage('responseFormat.message', [response.message])
      )

      buttons.push({
        text: i18nHelper.getMessage('text.ok'),
        color: 'primary',
        action: () => {
          addOnOkAction ? addOnOkAction() : emptyAction()
          this.closeAppDialogInfo()
        }
      })

      this.openAppDialogInfo(type, title, description, buttons)
    },
    openAppDialogInfo(type, title, description, buttons) {
      this.$store.dispatch(SHARED_OPEN_DIALOG_INFO, {
        type,
        title,
        description,
        buttons
      })
    },
    closeAppDialogInfo() {
      this.$store.dispatch(SHARED_CLOSE_DIALOG_INFO)
    }
  }
}

export default commonMixin
