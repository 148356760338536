import { i18nHelper } from '@/utils'

export const formValidationHelper = {
  requiredRules,
  usernameRules,
  passwordRules,
  confirmPasswordRules,
  emailRules,
  twoDecimalRules,
  numericRules,
  urlRules
}

const REGEX = Object.freeze({
  alphanumeric: /^[a-zA-Z0-9]+$/,
  username: /^[a-zA-Z0-9]+$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  notAllowMultipleSpace: /^\S+(?: \S+)*$/,
  decimalTwo: /^[0-9]\d*(\.\d{1,2})?$/,
  numeric: /^[0-9]+$/,
  url: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
})

/* Start Rules */
function requiredRules(fieldName, min = 0, max = 0) {
  return [
    requiredField(fieldName),
    notAllowMultipleSpace(),
    minMaxLength(min, max)
  ]
}

function usernameRules() {
  return [
    requiredField(i18nHelper.getMessage('text.username')),
    minMaxLength(6, 12),
    usernameFormat(),
    notAllowMultipleSpace()
  ]
}

function passwordRules() {
  return [
    requiredField(i18nHelper.getMessage('text.password')),
    minMaxLength(6, 18)
  ]
}

function confirmPasswordRules(targetValue, value) {
  return [
    requiredField(i18nHelper.getMessage('text.confirmPassword')),
    minMaxLength(6, 18),
    comparePasswordValue(targetValue, value)
  ]
}

function emailRules() {
  return [
    requiredField(i18nHelper.getMessage('text.email')),
    emailFormat(),
    notAllowMultipleSpace(),
    minMaxLength(1, 50)
  ]
}

function twoDecimalRules() {
  return [requiredField(), notAllowMultipleSpace(), twoDecimalFormat()]
}

function numericRules() {
  return [requiredField(), notAllowMultipleSpace(), numericFormat()]
}

function urlRules() {
  return [requiredField(), urlFormat()]
}
/* end Rules */

/* Start Shared Validation */
function requiredField(fieldName) {
  return (v) =>
    !!v ||
    (fieldName
      ? i18nHelper.getMessage('fieldErrorMessage.requiredFieldDynamic', [
          fieldName
        ])
      : i18nHelper.getMessage('fieldErrorMessage.requiredField'))
}

function minMaxLength(min, max) {
  if (min == 0 && max == 0) {
    return true
  } else {
    return (v) =>
      (v && v.length >= min && v.length <= max) ||
      i18nHelper.getMessage('fieldErrorMessage.exceedMinMaxLength', [min, max])
  }
}

function comparePasswordValue(targetValue, value) {
  return () =>
    targetValue === value ||
    i18nHelper.getMessage('fieldErrorMessage.confirmPasswordNotMatch')
}
/* End Shared Validation */

/* Start Format Validation */
function notAllowMultipleSpace() {
  return (v) =>
    (v && REGEX.notAllowMultipleSpace.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.multipleSpace')
}

function usernameFormat() {
  return (v) =>
    (v && REGEX.username.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.invalidUsernameFormat')
}

function emailFormat() {
  return (v) =>
    (v && REGEX.email.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.invalidEmailFormat')
}

function twoDecimalFormat() {
  return (v) =>
    (v && REGEX.decimalTwo.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.invalidDecimalFormat')
}

function numericFormat() {
  return (v) =>
    (v && REGEX.numeric.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.invalidNumericFormat')
}

function urlFormat() {
  return (v) =>
    (v && REGEX.url.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.invalidUrlFormat')
}
/* End Format Validation*/
