import { i18nHelper } from '@/utils'
import { SHARED } from '@/constants'

export const i18nService = {
  initI18n
}

function initI18n() {
  let language = i18nHelper.getLanguage()

  language
    ? i18nHelper.setLanguage(language)
    : i18nHelper.setLanguage(SHARED.DEFAULT_LOCALE)
}
