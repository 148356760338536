const PERMISSIONS = Object.freeze({
  // Admin User
  ADMIN_USER_CREATE: 'admin-user.create',
  ADMIN_USER_READ: 'admin-user.read',
  ADMIN_USER_UPDATE: 'admin-user.update',
  ADMIN_USER_DELETE: 'admin-user.delete',
  // Role
  ROLE_CREATE: 'role.create',
  ROLE_READ: 'role.read',
  ROLE_UPDATE: 'role.update',
  ROLE_DELETE: 'role.delete',
  // Product
  PRODUCT_CREATE: 'product.create',
  PRODUCT_READ: 'product.read',
  PRODUCT_UPDATE: 'product.update',
  PRODUCT_DELETE: 'product.delete',
  // Category
  CATEGORY_CREATE: 'category.create',
  CATEGORY_READ: 'category.read',
  CATEGORY_UPDATE: 'category.update',
  CATEGORY_DELETE: 'category.delete',
  // Agent Application
  AGENT_APPLICATION_CREATE: 'agent-application.create',
  AGENT_APPLICATION_READ: 'agent-application.read',
  AGENT_APPLICATION_UPDATE: 'agent-application.update',
  AGENT_APPLICATION_DELETE: 'agent-application.delete',
  // Contact Application
  CONTACT_APPLICATION_CREATE: 'contact-application.create',
  CONTACT_APPLICATION_READ: 'contact-application.read',
  CONTACT_APPLICATION_UPDATE: 'contact-application.update',
  CONTACT_APPLICATION_DELETE: 'contact-application.delete'
})

export { PERMISSIONS }
