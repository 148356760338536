import { apiService } from '@/services'

export const CONTACT_APPLICATION = {
  getContactApplications,
  getContactApplication
}

const URL = {
  ADMIN: 'admin',
  CONTACT_APPLICATION: 'contact-application'
}

function getContactApplications() {
  return apiService().get(`/${URL.ADMIN}/${URL.CONTACT_APPLICATION}`)
}

function getContactApplication(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.CONTACT_APPLICATION}/${id}`)
}
