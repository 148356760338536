import { ROUTE_NAME, ASIDE_CATEGORY } from '@/constants'

export const ACCESS_RIGHTS_SERVICE = {
  getAsideList
}

const ASIDE_LIST = [
  {
    path: '/home',
    route: ROUTE_NAME.HOME,
    icon: 'mdi-home',
    name: 'home',
    category: ASIDE_CATEGORY.MAIN,
    subGroup: []
  },
  {
    path: '/user',
    route: ROUTE_NAME.USER,
    icon: 'mdi-account',
    name: 'user',
    category: ASIDE_CATEGORY.ADMIN,
    subGroup: []
  },
  {
    path: '/news-and-events',
    route: ROUTE_NAME.NEWS_AND_EVENTS,
    icon: 'mdi-newspaper-variant-outline',
    name: 'newsAndEvents',
    category: ASIDE_CATEGORY.APP,
    subGroup: []
  },
  {
    path: '/contact-application',
    route: ROUTE_NAME.CONTACT_APPLICATION,
    icon: 'mdi-file-document-multiple',
    name: 'contactApplication',
    category: ASIDE_CATEGORY.APP,
    subGroup: []
  }
]

function getAsideList() {
  return ASIDE_LIST
}
