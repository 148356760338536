<template>
  <div class="page-header d-flex justify-space-between align-center">
    <div class="text-h5 text-capitalize">
      {{ $t(`text.${title}`) }}
    </div>
    <div>
      <v-breadcrumbs :items="breadcrumbs" class="px-0">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item link :to="item.to" :disabled="item.disabled">
            <v-icon v-if="item.text == 'home'" color="primary">
              mdi-home-outline
            </v-icon>
            <div v-else>
              {{ $t(`text.${item.text}`) }}
            </div>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppChildPageHeader',
  props: {
    title: {
      type: String,
      default: '',
      require: true
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
      require: true
    }
  }
}
</script>

<style></style>
