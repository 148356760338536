import { NEWS_AND_EVENTS } from '@/api'
import { apiHelper } from '@/utils'
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/store/shared.module'

/* Define Module Name */
const MODULE_NAME = 'newsAndEvents/'

/* Define Action Name */
const A_GET_NEWS_AND_EVENTS_LIST = 'getNewsAndEventsList'
const A_CREATE_NEWS_AND_EVENTS = 'createNewsAndEvents'
const A_GET_NEWS_AND_EVENTS = 'getNewsAndEvents'
const A_UPDATE_NEWS_AND_EVENTS = 'updateNewsAndEvents'
const A_DELETE_NEWS_AND_EVENTS = 'deleteNewsAndEvents'
const A_UPLOAD_NEWS_AND_EVENTS_IMAGE = 'uploadNewsAndEventsImage'

/* Define Reset State Action Name */
const A_INITIAL_GET_NEWS_AND_EVENTS_LIST_STATE =
  'initialGetNewsAndEventsListState'
const A_INITIAL_CREATE_NEWS_AND_EVENTS_STATE = 'initialCreateNewsAndEventsState'
const A_INITIAL_GET_NEWS_AND_EVENTS_STATE = 'initialGetNewsAndEventsState'
const A_INITIAL_UPDATE_NEWS_AND_EVENTS_STATE = 'initialUpdateNewsAndEventsState'
const A_INITIAL_DELETE_NEWS_AND_EVENTS_STATE = 'initialDeleteNewsAndEventsState'
const A_INITIAL_UPLOAD_NEWS_AND_EVENTS_IMAGE_STATE =
  'initialUploadNewsAndEventsImageState'

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_NEWS_AND_EVENTS_LIST = 'setGetNewsAndEventsList'
const M_CREATE_NEWS_AND_EVENTS = 'setCreateNewsAndEvents'
const M_GET_NEWS_AND_EVENTS = 'setGetNewsAndEvents'
const M_UPDATE_NEWS_AND_EVENTS = 'setUpdateNewsAndEvents'
const M_DELETE_NEWS_AND_EVENTS = 'setDeleteNewsAndEvents'
const M_UPLOAD_NEWS_AND_EVENTS_IMAGE = 'setUploadNewsAndEventsImage'

/* Define Reset State Mutation Name */
const M_INITIAL_GET_NEWS_AND_EVENTS_LIST_STATE =
  'setInitialNewsAndEventsListState'
const M_INITIAL_CREATE_NEWS_AND_EVENTS_STATE =
  'setInitialCreateNewsAndEventsState'
const M_INITIAL_GET_NEWS_AND_EVENTS_STATE = 'setInitialGetNewsAndEventsState'
const M_INITIAL_UPDATE_NEWS_AND_EVENTS_STATE =
  'setInitialUpdateNewsAndEventsState'
const M_INITIAL_DELETE_NEWS_AND_EVENTS_STATE =
  'setInitialDeleteNewsAndEventsState'
const M_INITIAL_UPLOAD_NEWS_AND_EVENTS_IMAGE_STATE =
  'setInitialUploadNewsAndEventsImageState'

/* Define Export Name */
export const NEWS_AND_EVENTS_GET_NEWS_AND_EVENTS_LIST =
  MODULE_NAME + A_GET_NEWS_AND_EVENTS_LIST
export const NEWS_AND_EVENTS_CREATE_NEWS_AND_EVENTS =
  MODULE_NAME + A_CREATE_NEWS_AND_EVENTS
export const NEWS_AND_EVENTS_GET_NEWS_AND_EVENTS =
  MODULE_NAME + A_GET_NEWS_AND_EVENTS
export const NEWS_AND_EVENTS_UPDATE_NEWS_AND_EVENTS =
  MODULE_NAME + A_UPDATE_NEWS_AND_EVENTS
export const NEWS_AND_EVENTS_DELETE_NEWS_AND_EVENTS =
  MODULE_NAME + A_DELETE_NEWS_AND_EVENTS
export const NEWS_AND_EVENTS_UPLOAD_NEWS_AND_EVENTS_IMAGE =
  MODULE_NAME + A_UPLOAD_NEWS_AND_EVENTS_IMAGE

/* Define Reset State Name */
export const NEWS_AND_EVENTS_INITIAL_GET_NEWS_AND_EVENTS_LIST_STATE =
  MODULE_NAME + A_INITIAL_GET_NEWS_AND_EVENTS_LIST_STATE
export const NEWS_AND_EVENTS_INITIAL_CREATE_NEWS_AND_EVENTS_STATE =
  MODULE_NAME + A_INITIAL_CREATE_NEWS_AND_EVENTS_STATE
export const NEWS_AND_EVENTS_INITIAL_GET_NEWS_AND_EVENTS_STATE =
  MODULE_NAME + A_INITIAL_GET_NEWS_AND_EVENTS_STATE
export const NEWS_AND_EVENTS_INITIAL_UPDATE_NEWS_AND_EVENTS_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_NEWS_AND_EVENTS_STATE
export const NEWS_AND_EVENTS_INITIAL_DELETE_NEWS_AND_EVENTS_STATE =
  MODULE_NAME + A_INITIAL_DELETE_NEWS_AND_EVENTS_STATE
export const NEWS_AND_EVENTS_INITIAL_UPLOAD_NEWS_AND_EVENTS_IMAGE_STATE =
  MODULE_NAME + A_INITIAL_UPLOAD_NEWS_AND_EVENTS_IMAGE_STATE

const state = {
  newsAndEventsList: {
    code: 0,
    complete: false,
    message: null,
    data: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  newNewsAndEvents: {
    code: 0,
    complete: false,
    message: null
  },
  newsAndEvents: {
    code: 0,
    complete: false,
    message: null,
    data: null
  },
  updateNewsAndEvents: {
    code: 0,
    complete: false,
    message: null
  },
  deleteNewsAndEvents: {
    code: 0,
    complete: false,
    message: null
  },
  uploadNewsAndEventsImage: {
    code: 0,
    complete: false,
    message: null
  }
}

//Getters
const getters = {}

// Actions
const actions = {
  [A_GET_NEWS_AND_EVENTS_LIST]({ dispatch, commit }, { data }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    NEWS_AND_EVENTS.getNewsAndEventsList(data)
      .then((response) => {
        result = response
        commit(M_GET_NEWS_AND_EVENTS_LIST, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
      .catch((error) => {
        result = error
        commit(M_GET_NEWS_AND_EVENTS_LIST, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
  },
  [A_CREATE_NEWS_AND_EVENTS]({ dispatch, commit }, { data }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    NEWS_AND_EVENTS.createNewsAndEvents(data)
      .then((response) => {
        result = response
        commit(M_CREATE_NEWS_AND_EVENTS, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
      .catch((error) => {
        result = error
        commit(M_CREATE_NEWS_AND_EVENTS, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
  },
  [A_GET_NEWS_AND_EVENTS]({ dispatch, commit }, { id }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    NEWS_AND_EVENTS.getNewsAndEvents(id)
      .then((response) => {
        result = response
        commit(M_GET_NEWS_AND_EVENTS, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
      .catch((error) => {
        result = error
        commit(M_GET_NEWS_AND_EVENTS, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
  },
  [A_UPDATE_NEWS_AND_EVENTS]({ dispatch, commit }, { id, data }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    NEWS_AND_EVENTS.updateNewsAndEvents(id, data)
      .then((response) => {
        result = response
        commit(M_UPDATE_NEWS_AND_EVENTS, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
      .catch((error) => {
        result = error
        commit(M_UPDATE_NEWS_AND_EVENTS, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
  },
  [A_DELETE_NEWS_AND_EVENTS]({ dispatch, commit }, { id }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    NEWS_AND_EVENTS.deleteNewsAndEvents(id)
      .then((response) => {
        result = response
        commit(M_DELETE_NEWS_AND_EVENTS, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
      .catch((error) => {
        result = error
        commit(M_DELETE_NEWS_AND_EVENTS, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
  },
  [A_UPLOAD_NEWS_AND_EVENTS_IMAGE]({ dispatch, commit }, { id, formData }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    NEWS_AND_EVENTS.uploadNewsAndEventsImage(id, formData)
      .then((response) => {
        result = response
        commit(M_UPLOAD_NEWS_AND_EVENTS_IMAGE, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
      .catch((error) => {
        result = error
        commit(M_UPLOAD_NEWS_AND_EVENTS_IMAGE, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
  },
  [A_INITIAL_GET_NEWS_AND_EVENTS_LIST_STATE]({ commit }) {
    commit(M_INITIAL_GET_NEWS_AND_EVENTS_LIST_STATE)
  },
  [A_INITIAL_CREATE_NEWS_AND_EVENTS_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_NEWS_AND_EVENTS_STATE)
  },
  [A_INITIAL_GET_NEWS_AND_EVENTS_STATE]({ commit }) {
    commit(M_INITIAL_GET_NEWS_AND_EVENTS_STATE)
  },
  [A_INITIAL_UPDATE_NEWS_AND_EVENTS_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_NEWS_AND_EVENTS_STATE)
  },
  [A_INITIAL_DELETE_NEWS_AND_EVENTS_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_NEWS_AND_EVENTS_STATE)
  },
  [A_INITIAL_UPLOAD_NEWS_AND_EVENTS_IMAGE_STATE]({ commit }) {
    commit(M_INITIAL_UPLOAD_NEWS_AND_EVENTS_IMAGE_STATE)
  }
}

// Mutations
const mutations = {
  [M_GET_NEWS_AND_EVENTS_LIST](state, result) {
    if (result.code === 0) {
      let p = result.pagination

      state.newsAndEventsList = {
        code: result.code,
        complete: true,
        message: null,
        data: result.data.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            title: x.title,
            description: x.description,
            link: x.link,
            image: x.image,
            createdAt: x.created_at,
            updatedAt: x.updated_at
          }
        }),
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      }
    } else {
      state.newsAndEventsList = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message),
        data: null,
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      }
    }
  },
  [M_CREATE_NEWS_AND_EVENTS](state, result) {
    state.newNewsAndEvents = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result.message)
    }
  },
  [M_GET_NEWS_AND_EVENTS](state, result) {
    if (result.code == 0) {
      let d = result.data

      state.newsAndEvents = {
        code: result.code,
        complete: true,
        message: null,
        data: {
          id: d.id,
          title: d.title,
          description: d.description,
          link: d.link,
          image: d.image,
          createdAt: d.created_at,
          updatedAt: d.updated_at
        }
      }
    } else {
      state.newsAndEvents = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message),
        data: null
      }
    }
  },
  [M_UPDATE_NEWS_AND_EVENTS](state, result) {
    state.updateNewsAndEvents = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result.message)
    }
  },
  [M_DELETE_NEWS_AND_EVENTS](state, result) {
    state.deleteNewsAndEvents = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result.message)
    }
  },
  [M_UPLOAD_NEWS_AND_EVENTS_IMAGE](state, result) {
    state.uploadNewsAndEventsImage = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result.message)
    }
  },
  [M_INITIAL_GET_NEWS_AND_EVENTS_LIST_STATE](state) {
    state.newsAndEventsList = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    }
  },
  [M_INITIAL_CREATE_NEWS_AND_EVENTS_STATE](state) {
    state.newNewsAndEvents = {
      code: 0,
      complete: false,
      message: null
    }
  },
  [M_INITIAL_GET_NEWS_AND_EVENTS_STATE](state) {
    state.newsAndEvents = {
      code: 0,
      complete: false,
      message: null,
      data: null
    }
  },
  [M_INITIAL_UPDATE_NEWS_AND_EVENTS_STATE](state) {
    state.updateNewsAndEvents = {
      code: 0,
      complete: false,
      message: null
    }
  },
  [M_INITIAL_DELETE_NEWS_AND_EVENTS_STATE](state) {
    state.deleteNewsAndEvents = {
      code: 0,
      complete: false,
      message: null
    }
  },
  [M_INITIAL_UPLOAD_NEWS_AND_EVENTS_IMAGE_STATE](state) {
    state.uploadNewsAndEventsImage = {
      code: 0,
      complete: false,
      message: null
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
