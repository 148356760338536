import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import routes from './routes'
import store from '@/store'
import { ROUTE_NAME } from '@/constants'
import { AUTH_IS_AUTHENTICATED } from '@/store/auth.module'

Vue.use(VueRouter)
Vue.use(Meta)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  /* 
  Redirect to login page if user not logged in and
  user is trying to access a restricted page .
  */
  const isAuthenticated = store.getters[AUTH_IS_AUTHENTICATED]
  let meta = to.meta

  if (meta.requiresAuth) {
    if (!isAuthenticated) {
      // Not logged in so redirect to login page
      next({ name: ROUTE_NAME.LOGIN })
    } else next()
  } else if (isAuthenticated) {
    // If user logged in and try to access login will redirect to home page
    if (to.name == ROUTE_NAME.LOGIN) next({ name: ROUTE_NAME.HOME })
    else next()
  } else next()
})

export default router
