<template>
  <div>
    <v-app-bar app fixed>
      <div class="page-container">
        <div class="d-flex justify-space-between align-center wh-100">
          <v-app-bar-nav-icon
            @click="drawerShow = !drawerShow"
          ></v-app-bar-nav-icon>

          <v-spacer></v-spacer>

          <v-btn icon @click="$vuetify.theme.dark = !$vuetify.theme.dark">
            <v-icon v-if="$vuetify.theme.dark">mdi-white-balance-sunny</v-icon>
            <v-icon v-else>mdi-weather-night</v-icon>
          </v-btn>

          <div>
            <v-menu offset-y open-on-hover transition="slide-y-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                  :ripple="false"
                  class="text-caption"
                >
                  <v-icon> mdi-translate </v-icon>
                  <v-icon right> mdi-chevron-down </v-icon>
                </v-btn>
              </template>
              <v-list nav dense class="px-2">
                <v-subheader class="font-weight-black min-width-100px">{{
                  $t('text.translations')
                }}</v-subheader>

                <template v-for="(i, index) in list.availableLanguageList()">
                  <v-list-item
                    :key="index"
                    :class="{
                      'v-list-item--active': currentLanguage == i.value
                    }"
                    @click="changeLanguage(i.value)"
                  >
                    <v-list-item-title>{{ i.text }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
            <v-menu offset-y open-on-hover transition="slide-y-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                  :ripple="false"
                  class="text-caption"
                >
                  {{ me.name }}
                  <v-icon right> mdi-chevron-down </v-icon>
                </v-btn>
              </template>
              <v-list nav dense>
                <v-list-item @click="signOut">
                  <v-list-item-title>{{
                    $t('text.signOut')
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </v-app-bar>
    <Aside :show="drawerShow" @update="asideUpdate"></Aside>
  </div>
</template>

<script>
import Aside from '@/components/layouts/Aside.vue'
import { listService } from '@/services'
import { i18nHelper } from '@/utils'

export default {
  name: 'Header',
  components: {
    Aside
  },
  props: {
    me: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    list: listService,
    currentLanguage: i18nHelper.getLanguage(),
    drawerShow: true
  }),
  methods: {
    signOut() {
      this.$emit('logout')
    },
    changeLanguage(language) {
      i18nHelper.setLanguage(language)
      this.currentLanguage = i18nHelper.getLanguage()
    },
    asideUpdate(value) {
      this.drawerShow = value
    }
  }
}
</script>

<style></style>
