import { CONTACT_APPLICATION } from '@/api'
import { apiHelper } from '@/utils'
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/store/shared.module'

/* Define Module Name */
const MODULE_NAME = 'contactApplication/'

/* Define Action Name */
const A_GET_CONTACT_APPLICATIONS = 'getContactApplications'
const A_GET_CONTACT_APPLICATION = 'getContactApplication'

/* Define Reset State Action Name */
const A_INITIAL_GET_CONTACT_APPLICATIONS_STATE =
  'initialGetContactApplicationsState'
const A_INITIAL_GET_CONTACT_APPLICATION_STATE =
  'initialGetContactApplicationState'

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_CONTACT_APPLICATIONS = 'setGetContactApplications'
const M_GET_CONTACT_APPLICATION = 'setGetContactApplication'

/* Define Reset State Mutation Name */
const M_INITIAL_GET_CONTACT_APPLICATIONS_STATE =
  'setInitialContactApplicationsState'
const M_INITIAL_GET_CONTACT_APPLICATION_STATE =
  'setInitialGetContactApplicationState'

/* Define Export Name */
export const CONTACT_APPLICATION_GET_CONTACT_APPLICATIONS =
  MODULE_NAME + A_GET_CONTACT_APPLICATIONS
export const CONTACT_APPLICATION_GET_CONTACT_APPLICATION =
  MODULE_NAME + A_GET_CONTACT_APPLICATION

/* Define Reset State Name */
export const CONTACT_APPLICATION_INITIAL_GET_CONTACT_APPLICATIONS_STATE =
  MODULE_NAME + A_INITIAL_GET_CONTACT_APPLICATIONS_STATE
export const CONTACT_APPLICATION_INITIAL_GET_CONTACT_APPLICATION_STATE =
  MODULE_NAME + A_INITIAL_GET_CONTACT_APPLICATION_STATE

const state = {
  contactApplications: {
    code: 0,
    complete: false,
    message: null,
    data: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  contactApplication: {
    code: 0,
    complete: false,
    message: null,
    data: null
  }
}

//Getters
const getters = {}

// Actions
const actions = {
  [A_GET_CONTACT_APPLICATIONS]({ dispatch, commit }, { data }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    CONTACT_APPLICATION.getContactApplications(data)
      .then((response) => {
        result = response
        commit(M_GET_CONTACT_APPLICATIONS, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
      .catch((error) => {
        result = error
        commit(M_GET_CONTACT_APPLICATIONS, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
  },
  [A_GET_CONTACT_APPLICATION]({ dispatch, commit }, { id }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    CONTACT_APPLICATION.getContactApplication(id)
      .then((response) => {
        result = response
        commit(M_GET_CONTACT_APPLICATION, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
      .catch((error) => {
        result = error
        commit(M_GET_CONTACT_APPLICATION, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
  },
  [A_INITIAL_GET_CONTACT_APPLICATIONS_STATE]({ commit }) {
    commit(M_INITIAL_GET_CONTACT_APPLICATIONS_STATE)
  },
  [A_INITIAL_GET_CONTACT_APPLICATION_STATE]({ commit }) {
    commit(M_INITIAL_GET_CONTACT_APPLICATION_STATE)
  }
}

// Mutations
const mutations = {
  [M_GET_CONTACT_APPLICATIONS](state, result) {
    if (result.code === 0) {
      let p = result.pagination

      state.contactApplications = {
        code: result.code,
        complete: true,
        message: null,
        data: result.data.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            name: x.name,
            phoneNumber: x.phone_number,
            email: x.email,
            description: x.description,
            createdAt: x.created_at
          }
        }),
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      }
    } else {
      state.contactApplications = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message),
        data: null,
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      }
    }
  },
  [M_GET_CONTACT_APPLICATION](state, result) {
    if (result.code === 0) {
      let d = result.data

      state.contactApplication = {
        code: result.code,
        complete: true,
        message: null,
        data: {
          id: d.id,
          name: d.name,
          phoneNumber: d.phone_number,
          email: d.email,
          description: d.description,
          createdAt: d.created_at
        }
      }
    } else {
      state.contactApplication = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message),
        data: null
      }
    }
  },
  [M_INITIAL_GET_CONTACT_APPLICATIONS_STATE](state) {
    state.contactApplications = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    }
  },
  [M_INITIAL_GET_CONTACT_APPLICATION_STATE](state) {
    state.contactApplication = {
      code: 0,
      complete: false,
      data: null,
      message: null
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
