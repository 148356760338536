import axios from 'axios'
import { jwtHelper, apiHelper } from '@/utils'
import { API } from '@/constants'

//Reference https://github.com/axios/axios

export function apiService(URL = null, isAuth = true, withMedia = false) {
  let baseURL = URL === null ? API.BASE_API_URL : URL
  let jwtToken = jwtHelper.getBearerToken()
  let header = isAuth
    ? {
        Authorization: jwtToken,
        'Content-Type': withMedia ? 'multipart/form-data' : 'application/json'
      }
    : {}

  let instance = axios.create({
    baseURL,
    headers: header,
    withCredentials: false
  })

  instance.interceptors.request.use(
    function (config) {
      // Do something before request is sent

      //Remove empty value params
      config.params = apiHelper.removeParamsWithoutValue(config.params)

      //Update activity time when fire API
      jwtHelper.setLastActivityTime()

      return config
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      let res = response.data

      return res
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      let err = error.response.data

      return Promise.reject(err)
    }
  )

  return instance
}
