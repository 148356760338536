import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false

//Third part plugins
import i18n from '@/plugins/vue-i18n.js'
import vuetify from './plugins/vuetify'
import './plugins/vue-meta'
import './plugins/vue2-editor'

//Component
import AppFormField from '@/components/base/AppFormField'
import AppDialogBlank from '@/components/base/AppDialogBlank'
import AppChildPageHeader from '@/components/base/AppChildPageHeader'
Vue.component('AppFormField', AppFormField)
Vue.component('AppDialogBlank', AppDialogBlank)
Vue.component('AppChildPageHeader', AppChildPageHeader)

//Customize Style
import '@/assets/sass/init.scss'

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
