import { apiService } from '@/services'

export const USER = {
  getUsers,
  createUser,
  getUser,
  updateUser,
  deleteUser
}

const URL = {
  ADMIN: 'admin',
  USERS: 'users'
}

function getUsers() {
  return apiService().get(`/${URL.ADMIN}/${URL.USERS}`)
}

function createUser(data) {
  let requestBody = {
    username: data.username,
    name: data.name,
    email: data.email,
    password: data.password,
    role_id: data.roleId
  }
  return apiService().post(`/${URL.ADMIN}/${URL.USERS}`, requestBody)
}

function getUser(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.USERS}/${id}`)
}

function updateUser(id, data) {
  let requestBody = {
    username: data.username,
    name: data.name,
    email: data.email,
    password: data.password,
    role_id: data.roleId
  }
  return apiService().put(`/${URL.ADMIN}/${URL.USERS}/${id}`, requestBody)
}

function deleteUser(id) {
  return apiService().delete(`/${URL.ADMIN}/${URL.USERS}/${id}`)
}
