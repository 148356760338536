<template>
  <div class="wh-100">
    <Header :me="me" @logout="logout(false)"></Header>
    <v-main class="main h-100">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
  </div>
</template>

<script>
import Header from '@/components/layouts/Header.vue'
import commonMixin from '@/mixins/common.mixin'
import { i18nHelper, jwtHelper } from '@/utils'
import {
  AUTH_ME,
  AUTH_LOGOUT,
  AUTH_REFRESH,
  AUTH_INITIAL_ME_STATE
} from '@/store/auth.module'

export default {
  name: 'Layout',
  components: {
    Header
  },
  mixins: [commonMixin],
  data: () => ({
    me: {
      name: ''
    },
    checkActivityInterval: null
  }),
  computed: {
    meComplete() {
      return this.$store.state.auth.me.complete
    }
  },
  watch: {
    meComplete() {
      let response = this.$store.state.auth.me
      if (response.complete) {
        this.meCompleteAction(response)
      }
    }
  },
  created() {
    this.initLayout()
  },
  beforeDestroy() {
    clearInterval(this.checkActivityInterval)
  },
  methods: {
    meCompleteAction(response) {
      let title = i18nHelper.getMessage('text.me')

      if (response.code == 0) {
        this.me = response.data
      } else {
        this.openErrorAppDialogInfo(title, response)
      }
      this.initialGetMe()
    },
    setActivityTimeChecker() {
      this.checkActivityInterval = setInterval(() => {
        this.checkUserActivityTime()
      }, 10000)
    },
    checkUserActivityTime() {
      let minute = 60 * 1000
      let lastActivityTime = jwtHelper.getLastActivityTime()
      let expirationTime = jwtHelper.getSessionExpirationTime()
      let currentTime = new Date().getTime()

      if (expirationTime != 0) {
        if (currentTime > expirationTime - 10 * minute) {
          this.closeAppDialogInfo()
          this.openSessionExpiredDialog()
        } else {
          if (lastActivityTime > expirationTime - 30 * minute) {
            this.refreshToken()
          }
        }
      }
    },
    openSessionExpiredDialog() {
      let isSessionExpired = true
      let type = 'error'
      let title = i18nHelper.getMessage('text.sessionExpired')
      let description = []
      let buttons = []

      description.push(i18nHelper.getMessage('message.sessionExpiredMessage'))

      buttons.push({
        text: i18nHelper.getMessage('text.ok'),
        color: 'primary',
        action: () => {
          this.closeAppDialogInfo()
          this.logout(isSessionExpired)
        }
      })

      this.openAppDialogInfo(type, title, description, buttons)
    },
    logout(isSessionExpired) {
      this.$store.dispatch(AUTH_LOGOUT, { isSessionExpired })
    },
    getMe() {
      this.$store.dispatch(AUTH_ME)
    },
    refreshToken() {
      this.$store.dispatch(AUTH_REFRESH)
    },
    initialGetMe() {
      this.$store.dispatch(AUTH_INITIAL_ME_STATE)
    },
    initLayout() {
      this.getMe()
      this.setActivityTimeChecker()
    }
  }
}
</script>

<style lang="scss"></style>
