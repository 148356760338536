const SHARED = Object.freeze({
  DEFAULT_LOCALE: 'en',
  FALLBACK_LOCALE: 'en',
  LAYOUT_DEFAULT: 'layout',
  LAYOUT_BLANK: 'layout-blank',
  LAYOUT_ERROR: 'layout-error',
  DEFAULT_DATE_TODAY: new Date().toISOString().substr(0, 10),
  DEFAULT_PAGE_SIZE_ITEMS: [10, 30, 50, 100],
  DEFAULT_CURRENCY: 'MYR'
})

const LOCAL = Object.freeze({
  I18N_NAME: 'language',
  SESSION_EXPIRATION_TIME: 'sessionExpirationTime',
  LAST_ACTIVITY_TIME: 'lastActivityTime',
  USER_PERMISSIONS: 'userPermissions',
  TOKEN: 'token',
  CONSTANTS: 'constants'
})

const JWT = Object.freeze({
  JWT_TOKEN_TYPE: 'Bearer '
})

const API = Object.freeze({
  BASE_API_URL: process.env.VUE_APP_BASE_API_URL,
  DATETIME_FROM_START_TIME: ' 00:00:00.000',
  DATETIME_TO_END_TIME: ' 23:59:59.999'
})

const ASIDE_CATEGORY = Object.freeze({
  MAIN: 'main',
  ADMIN: 'admin',
  APP: 'app',
  REPORT: 'report',
  CONFIGURATION: 'configuration'
})

const FORM_FIELD_TYPE = Object.freeze({
  TEXT: 'text',
  PASSWORD: 'password',
  SELECT: 'select',
  DATE: 'date',
  TEXTAREA: 'textarea',
  DATE_TIME: 'date-time',
  RICH_TEXT: 'rich-text',
  IMAGE_FILE_INPUT: 'image-file-input'
})

const DATA_TABLE_LAYOUT = Object.freeze({
  APP_TABLE_ACTIONS: 'AppTableActions',
  APP_CHECK_CIRCLE: 'AppCheckCircle',
  APP_TABLE_DESCRIPTION: 'AppTableDescription'
})

const COOKIE = Object.freeze({
  REMEMBER_USERNAME: 'ru'
})

export {
  SHARED,
  LOCAL,
  JWT,
  API,
  ASIDE_CATEGORY,
  FORM_FIELD_TYPE,
  DATA_TABLE_LAYOUT,
  COOKIE
}
