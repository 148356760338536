import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#E1BB6C',
        secondary: '#CADBE9',
        tertiary: '#0D0D12',
        error: '#ef476f',
        text: '#e1dfe9',
        label: '#908e9b',
        disabled: '#565360',
        background: '#f3f4f6',
        surface: '#ffffff'
      },
      dark: {
        primary: '#E1BB6C',
        secondary: '#CADBE9',
        tertiary: '#0D0D12',
        error: '#ef476f',
        text: '#e1dfe9',
        label: '#908e9b',
        disabled: '#565360',
        background: '#f3f4f6',
        surface: '#ffffff'
      }
    },
    options: { customProperties: true }
  }
})
