import { apiService } from '@/services'

export const AUTH = {
  me,
  login,
  logout,
  refresh
}

const URL = {
  ADMIN: 'admin',
  AUTH: 'auth',
  ME: 'me',
  LOGIN: 'login',
  LOGOUT: 'logout',
  REFRESH: 'refresh'
}

function me() {
  return apiService().post(`/${URL.ADMIN}/${URL.AUTH}/${URL.ME}`)
}

function login(data) {
  let requestBody = {
    username: data.username,
    password: data.password
  }
  return apiService(null, false).post(
    `/${URL.ADMIN}/${URL.AUTH}/${URL.LOGIN}`,
    requestBody
  )
}

function logout() {
  return apiService().post(`/${URL.ADMIN}/${URL.AUTH}/${URL.LOGOUT}`)
}

function refresh() {
  return apiService().post(`/${URL.ADMIN}/${URL.AUTH}/${URL.REFRESH}`)
}
