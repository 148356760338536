const ROUTE_NAME = Object.freeze({
  HOME: 'home',
  LOGIN: 'login',
  /* User */
  USER: 'user',
  USER_LIST: 'user-list',
  USER_NEW: 'user-new',
  USER_DETAILS: 'user-details',
  /* Contact Application */
  CONTACT_APPLICATION: 'contact-application',
  CONTACT_APPLICATION_LIST: 'contact-application-list',
  CONTACT_APPLICATION_DETAILS: 'contact-application-details',
  /* News and Events */
  NEWS_AND_EVENTS: 'news-and-events',
  NEWS_AND_EVENTS_LIST: 'news-and-events-list',
  NEWS_AND_EVENTS_NEW: 'news-and-events-new',
  NEWS_AND_EVENTS_DETAILS: 'news-and-events-details',
  /* Error */
  ERROR_403: 'error-403',
  ERROR_404: 'error-404'
})

export { ROUTE_NAME }
