/* Define Module Name */
const MODULE_NAME = 'shared/'

/* Define Action Name */
const A_OPEN_LOADING = 'openLoading'
const A_CLOSE_LOADING = 'closeLoading'
const A_OPEN_DIALOG_INFO = 'openDialogInfo'
const A_CLOSE_DIALOG_INFO = 'closeDialogInfo'

/* Define Getter Name */

/* Define Mutation Name */
const M_OPEN_LOADING = 'setOpenLoading'
const M_CLOSE_LOADING = 'setCloseLoading'
const M_OPEN_DIALOG_INFO = 'setOpenDialogInfo'
const M_CLOSE_DIALOG_INFO = 'setCloseDialogInfo'

/* Define Export Name */
export const SHARED_OPEN_LOADING = MODULE_NAME + A_OPEN_LOADING
export const SHARED_CLOSE_LOADING = MODULE_NAME + A_CLOSE_LOADING
export const SHARED_OPEN_DIALOG_INFO = MODULE_NAME + A_OPEN_DIALOG_INFO
export const SHARED_CLOSE_DIALOG_INFO = MODULE_NAME + A_CLOSE_DIALOG_INFO

const state = {
  isLoading: false,
  dialogInfo: {
    show: false,
    type: null,
    title: null,
    description: [],
    buttons: []
  }
}

//Getters
const getters = {}

// Actions
const actions = {
  [A_OPEN_LOADING]({ commit }) {
    commit(M_OPEN_LOADING)
  },
  [A_CLOSE_LOADING]({ commit }) {
    commit(M_CLOSE_LOADING)
  },
  [A_OPEN_DIALOG_INFO]({ commit }, { type, title, description, buttons }) {
    commit(M_OPEN_DIALOG_INFO, { type, title, description, buttons })
  },
  [A_CLOSE_DIALOG_INFO]({ commit }) {
    commit(M_CLOSE_DIALOG_INFO)
  }
}

// Mutations
const mutations = {
  [M_OPEN_LOADING](state) {
    state.isLoading = true
  },
  [M_CLOSE_LOADING](state) {
    state.isLoading = false
  },
  [M_OPEN_DIALOG_INFO](state, { type, title, description, buttons }) {
    state.dialogInfo = {
      show: true,
      type: type,
      title: title,
      description: description,
      buttons: buttons
    }
  },
  [M_CLOSE_DIALOG_INFO](state) {
    state.dialogInfo = {
      show: false,
      type: null,
      title: null,
      description: [],
      buttons: []
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
