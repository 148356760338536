import { AUTH } from '@/api'
import { LOCAL } from '@/constants'
import { jwtHelper, uiHelper, i18nHelper, apiHelper } from '@/utils'
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/store/shared.module'

/* Define Module Name */
const MODULE_NAME = 'auth/'

/* Define Action Name */
const A_ME = 'me'
const A_LOGIN = 'login'
const A_LOGOUT = 'logout'
const A_REFRESH = 'refresh'
/* Define Reset State Action Name */
const A_INITIAL_LOGIN_STATE = 'initialLoginState'
const A_INITIAL_ME_STATE = 'initialMeState'

/* Define Getter Name */
const G_IS_AUTHENTICATED = 'isAuthenticated'

/* Define Mutation Name */
const M_ME = 'setMe'
const M_LOGIN = 'setLogin'
const M_LOGOUT = 'setLogout'
const M_LOGOUT_SESSION_EXPIRED = 'setLogoutSessionExpired'
const M_REFRESH = 'setRefresh'
/* Define Reset State Mutation Name */
const M_INITIAL_LOGIN_STATE = 'setInitialLoginState'
const M_INITIAL_ME_STATE = 'setInitialMeState'

/* Define Export Name */
export const AUTH_ME = MODULE_NAME + A_ME
export const AUTH_LOGIN = MODULE_NAME + A_LOGIN
export const AUTH_LOGOUT = MODULE_NAME + A_LOGOUT
export const AUTH_REFRESH = MODULE_NAME + A_REFRESH
export const AUTH_IS_AUTHENTICATED = MODULE_NAME + G_IS_AUTHENTICATED

/* Define Reset State Name */
export const AUTH_INITIAL_LOGIN_STATE = MODULE_NAME + A_INITIAL_LOGIN_STATE
export const AUTH_INITIAL_ME_STATE = MODULE_NAME + A_INITIAL_ME_STATE

const state = {
  me: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  login: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  logout: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
}

//Getters
const getters = {
  [G_IS_AUTHENTICATED]: (state) => {
    let result = false
    let token = jwtHelper.getToken()

    if (state.login.complete) {
      token = jwtHelper.getToken()
    }

    if (![null, undefined, ''].includes(token)) {
      result = true
    }

    return result
  }
}

// Actions
const actions = {
  [A_ME]({ dispatch, commit }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    AUTH.me()
      .then((response) => {
        result = response
        commit(M_ME, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
      .catch((error) => {
        result = error
        commit(M_LOGIN, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
  },
  async [A_LOGIN]({ dispatch, commit }, { data }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    await AUTH.login(data)
      .then((response) => {
        result = response
        commit(M_LOGIN, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
      .catch((error) => {
        result = error
        commit(M_LOGIN, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
  },
  async [A_LOGOUT]({ dispatch, commit }, { isSessionExpired }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    if (!isSessionExpired) {
      await AUTH.logout()
        .then((response) => {
          result = response
          commit(M_LOGOUT, result)
          dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
        })
        .catch((error) => {
          result = error
          commit(M_LOGOUT, result)
          dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
        })
    } else {
      commit(M_LOGOUT_SESSION_EXPIRED, isSessionExpired)
    }
  },
  async [A_REFRESH]({ commit }) {
    let result
    await AUTH.refresh()
      .then((response) => {
        result = response
        commit(M_REFRESH, result)
      })
      .catch((error) => {
        result = error
        commit(M_REFRESH, result)
      })
  },
  [A_INITIAL_LOGIN_STATE]({ commit }) {
    commit(M_INITIAL_LOGIN_STATE)
  },
  [A_INITIAL_ME_STATE]({ commit }) {
    commit(M_INITIAL_ME_STATE)
  }
}

// Mutations
const mutations = {
  [M_ME](state, result) {
    if (result.code === 0) {
      let data = result.data

      state.me = {
        code: result.code,
        complete: true,
        data: {
          id: data.id,
          name: data.name,
          username: data.username,
          createdAt: data.created_at,
          updatedAt: data.updated_at
        },
        message: null
      }
    } else {
      state.me = {
        code: result.code,
        complete: true,
        data: null,
        message: result.message
          ? apiHelper.sortOutMessage(result.message)
          : result.error
      }
    }
  },
  [M_LOGIN](state, result) {
    if (result.code === 0) {
      let data = result.data

      state.login = {
        code: result.code,
        complete: true,
        message: i18nHelper.getMessage('text.signInSuccessfully'),
        data: {
          accessToken: data.access_token,
          tokenType: data.token_type,
          expiryIn: data.expires_in
        }
      }

      jwtHelper.setToken(data.access_token)
      jwtHelper.setSessionExpirationTime(data.expires_in)
    } else {
      state.login = {
        code: result.code,
        complete: true,
        data: null,
        message: result.error
      }
    }
  },
  [M_LOGOUT](state, result) {
    if (result.code === 0) {
      state.logout = {
        code: result.code,
        complete: true,
        message: result.message
      }
    } else {
      state.logout = {
        code: result.code,
        complete: true,
        message: result.error
      }
    }
    jwtHelper.removeToken()
    jwtHelper.removeLastActivityTime()
    jwtHelper.removeSessionExpirationTime()
    uiHelper.removeLocalStorage(LOCAL.CONSTANTS)
    uiHelper.reloadPage()
  },
  [M_LOGOUT_SESSION_EXPIRED](isSessionExpired) {
    if (isSessionExpired) {
      jwtHelper.removeToken()
      jwtHelper.removeLastActivityTime()
      jwtHelper.removeSessionExpirationTime()
      uiHelper.removeLocalStorage(LOCAL.CONSTANTS)
      uiHelper.reloadPage()
    }
  },
  [M_REFRESH](state, result) {
    if (result.code === 0) {
      let data = result.data
      state.login = {
        code: result.code,
        complete: true,
        message: null,
        data: {
          accessToken: data.access_token,
          tokenType: data.token_type,
          expiryIn: data.expires_in
        }
      }

      jwtHelper.setToken(data.access_token)
      jwtHelper.setSessionExpirationTime(data.expires_in)
    } else {
      state.login = {
        code: result.code,
        complete: true,
        message: result.error
      }
    }
  },
  [M_INITIAL_LOGIN_STATE](state) {
    state.login = {
      code: 0,
      complete: false,
      data: null,
      message: null
    }
  },
  [M_INITIAL_ME_STATE](state) {
    let data = state.me.data

    state.me = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
