<template>
  <v-main class="background">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </v-main>
</template>

<script>
export default {
  name: 'LayoutBlank'
}
</script>

<style></style>
